<template>
	<WDialog v-model="dialog" :max-width="$vuetify.breakpoint.mdAndUp ? '40vw' : '90vw'" :title="$t('projects.delete_a_project')" @close="$emit('close')">
		<v-layout>
			<v-flex>
				{{ $t('projects.are_you_sure_about_deleting_this_project', { name: value.name }) }}
				<v-checkbox v-model="deleteAssociatedTask" color="primary" :label="$t('offers.delete_tasks_title_also')" hide-details />
			</v-flex>
		</v-layout>
		<template v-slot:actions>
			<v-layout row>
				<v-spacer />
				<WButton flat @click="doDelete()">{{ $t('actions.yes') }}</WButton>
				<WButton flat @click="$emit('close')">{{ $t('actions.no') }}</WButton>
			</v-layout>
		</template>
	</WDialog>
</template>

<script>
import ProjectsManagerModuleGuard from '@/mixins/ModulesGuards/Offers/ProjectsManagerModuleGuard'

export default {
	name: 'ProjectDeletion',
	mixins: [ProjectsManagerModuleGuard],
	props: {
		value: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			dialog: true,
			deleteAssociatedTask: false
		}
	},
	methods: {
		doDelete: function () {
			if (this.deleteAssociatedTask) {
				this.service
					.deleteAllServicesIntervals(this.value.id)
					.then(() => {
						this.service.deleteProject(this.accountingFirmId, this.vendorId, this.value.id)
					})
					.then(() => {
						this.$emit('close', true)
					})
			} else {
				this.service.deleteProject(this.accountingFirmId, this.vendorId, this.value.id).then(() => {
					this.$emit('close', true)
				})
			}
		}
	}
}
</script>
